<template>
  <div>
    <!-- Loading and Error if no session found -->
    <details-loading
      v-if="!session"
      type="Session"
      router-to="Sessions"
      :data="allSessions"
    ></details-loading>

    <!-- There is a session, display it -->
    <div class="row mt-4" v-else>
      <div class="col-12">
        <aad-back></aad-back>
      </div>

      <div class="col-lg-8 p-2">
        <h1>{{ session.SessionCode + " - " + session.Name }}</h1>
        <p><session-time-string :session="session"></session-time-string></p>
        <hr class="land-page-hr" />

        <!-- Description -->
        <div>
          <h2>Description</h2>
          <p id="sessions-description-text">
            {{ session.Description || "No Description Available." }}
          </p>
        </div>

        <!-- Learning Objectives -->
        <div
          v-if="session.Objective1 || session.Objective2 || session.Objective3"
        >
          <h2>Learning Objectives</h2>
          <p>Following this course, the attendee should be able to:</p>
          <ul>
            <li v-if="session.Objective1">
              {{ session.Objective1 }}
            </li>
            <li v-if="session.Objective2">
              {{ session.Objective2 }}
            </li>
            <li v-if="session.Objective3">
              {{ session.Objective3 }}
            </li>
          </ul>
        </div>

        <!-- Session Schedules -->
        <div
          class="meeting-app-info-block mx-0"
          v-if="sessionSchedules && sessionSchedules.length > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>Session Schedule</h4>
          </div>
          <div class="meeting-app-info-body">
            <div
              class="row meeting-app-info-item"
              v-for="schedule in sessionSchedules"
              :key="schedule.id"
            >
              <div class="col-md-3">
                <session-time-string
                  type="start-time"
                  :session="schedule"
                ></session-time-string>
              </div>
              <div class="col-md-9">
                <p class="m-0 font-weight-bold">{{ schedule.Title }}</p>
                <p v-if="schedule.SpeakerRefs" class="font-italic m-0">
                  {{ speakersForSchedule(schedule) }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <!-- Disclosures -->
        <div
          class="meeting-app-info-block mx-0"
          v-if="allSessionFaculty && allSessionFaculty.length > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>Disclosures</h4>
          </div>
          <div class="meeting-app-info-body">
            <div
              class="row meeting-app-info-item"
              v-for="speaker in allSessionFaculty.filter((spk) => {return spk.Disclosures != null})" 
              :key="speaker.id"
            >
              <h5 class="col-12">{{ speaker.DisplayName }}</h5>
              <div v-if="speaker.Disclosures.trim().startsWith('no financial')">
                No financial relationships exist with ineligble companies.
              </div>
              <div v-else>
                {{ speaker.Disclosures.replaceAll("\&ndash\;", "-") }}
              </div>
            </div>
          </div>
        </div>
        <hr />
        <!-- Attendee Session Notes -->
        <div class="mt-3" v-if="isLoggedIn">
          <h2>Session Notes</h2>
          <attendee-notes
            ref="notes"
            note-type="session"
            :link-id="session.id"
            :title="session.SessionCode + ' ' + session.Name"
          ></attendee-notes>
        </div>
      </div>

      <!-- Right Sidebar -->
      <div class="col-lg-4 p-1">
        <!-- Favorites -->
        <a
          href="#/"
          v-if="isLoggedIn"
          class="btn-primary-solid btn mb-2 w-100"
          @click="toggleFavorite"
        >
          <aad-icon
            :name="isFavorited ? 'star-filled' : 'star-thick'"
            class="favorite-star"
            :class="isFavorited ? 'star-filled' : 'star-thick'"
          ></aad-icon>
          {{ isFavorited ? "Remove from Favorites" : "Add to Favorites" }}
        </a>

        <!-- Event Details -->
        <div class="meeting-app-info-block">
          <div class="meeting-app-info-header row">
            <h4>Event Details</h4>
          </div>
          <div class="meeting-app-info-body">
            <div class="row meeting-app-info-item">
              <div class="col-xl-4 meeting-app-info-label">Date</div>
              <div class="col-xl-8 meeting-app-data">
                <session-time-string
                  type="start-date"
                  :session="session"
                ></session-time-string>
              </div>
            </div>
            <div class="row meeting-app-info-item">
              <div class="col-xl-4 meeting-app-info-label">Session Code</div>
              <div class="col-xl-8 meeting-app-data">
                {{ session.SessionCode ? session.SessionCode : "TBD" }}
              </div>
            </div>
            <div class="row meeting-app-info-item">
              <div class="col-xl-4 meeting-app-info-label">Time</div>
              <div class="col-xl-8 meeting-app-data">
                <session-time-string type="start-time" :session="session" /> -
                <session-time-string type="end-time" :session="session" />
              </div>
            </div>
            <div class="row meeting-app-info-item">
              <div class="col-xl-4 meeting-app-info-label">Location</div>
              <div class="col-xl-8 meeting-app-data">
                {{
                  session.LocationName
                    ? session.LocationName
                    : "Location To Be Announced"
                }}
              </div>
            </div>
            <div class="row meeting-app-info-item">
              <div class="col-xl-4 meeting-app-info-label">CME Credits</div>
              <div class="col-xl-8 meeting-app-data">
                {{ session.MaxCredits ? session.MaxCredits.toFixed(2) : 0 }}
              </div>
            </div>
            <div class="row meeting-app-info-item">
              <div class="col-xl-4 meeting-app-info-label">Type</div>
              <div class="col-xl-8 session-tags">
                <span class="tag-pill tag-pill-gray d-inline-block mb-2">{{
                  session.Type
                }}</span>
              </div>
            </div>
            <div
              class="row meeting-app-info-item"
              v-if="session.Categories && session.Categories.length > 0"
            >
              <div class="col-xl-6 meeting-app-info-label">Categories</div>
              <div class="col-xl-6 session-tags">
                <span
                  class="tag-pill tag-pill-gray d-inline-block mb-2"
                  v-for="category in session.Categories"
                  :key="category"
                >
                  {{ category }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Directors/Co Directors -->
        <div
          class="meeting-app-info-block"
          v-if="
            (directors && directors.length > 0) ||
            (codirectors && codirectors.length > 0)
          "
        >
          <div class="meeting-app-info-header row">
            <h4>Directors/Co-Directors</h4>
          </div>
          <div class="meeting-app-info-body">
            <div
              class="row meeting-app-info-item"
              v-for="director in directors"
              :key="director.SpeakerId"
            >
              <router-link
                @click.native="
                  logNaviagationToSpeaker(
                    director.SpeakerId,
                    director.DisplayName
                  )
                "
                @click.native.middle="
                  logNaviagationToSpeaker(
                    director.SpeakerId,
                    director.DisplayName
                  )
                "
                :to="{
                  name: 'SpeakerDetails',
                  params: { id: director.SpeakerId },
                }"
                class="text-link-light-bg"
              >
                <span>{{ director.DisplayName }}</span>
              </router-link>
            </div>
            <div
              class="row meeting-app-info-item"
              v-for="codirector in codirectors"
              :key="codirector.SpeakerId"
            >
              <router-link
                @click.native="
                  logNaviagationToSpeaker(
                    codirector.SpeakerId,
                    codirector.DisplayName
                  )
                "
                @click.native.middle="
                  logNaviagationToSpeaker(
                    codirector.SpeakerId,
                    codirector.DisplayName
                  )
                "
                :to="{
                  name: 'SpeakerDetails',
                  params: { id: codirector.SpeakerId },
                }"
                class="text-link-light-bg"
              >
                <span>{{ codirector.DisplayName }}</span>
              </router-link>
            </div>
          </div>
        </div>

        <!-- Speakers -->
        <div
          class="meeting-app-info-block"
          v-if="speakers && speakers.length > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>Speakers</h4>
          </div>
          <div class="meeting-app-info-body">
            <div
              class="row meeting-app-info-item"
              v-for="speaker in speakers"
              :key="speaker.SpeakerId"
            >
              <router-link
                @click.native="
                  logNaviagationToSpeaker(
                    speaker.SpeakerId,
                    speaker.DisplayName
                  )
                "
                @click.native.middle="
                  logNaviagationToSpeaker(
                    speaker.SpeakerId,
                    speaker.DisplayName
                  )
                "
                :to="{
                  name: 'SpeakerDetails',
                  params: { id: speaker.SpeakerId },
                }"
                class="text-link-light-bg"
              >
                <span>{{ speaker.DisplayName }}</span>
              </router-link>
            </div>
          </div>
        </div>

        <!-- CME -->
        <div
          class="meeting-app-info-block"
          v-if="isLoggedIn && session.MaxCredits > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>CME</h4>
          </div>
          <div class="meeting-app-info-body">
            <CME-session-card :session="session" format="compact">
            </CME-session-card>
          </div>
        </div>

        <!-- Handouts -->
        <div
          class="meeting-app-info-block"
          v-if="session.Handouts && session.Handouts.length > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>Handouts</h4>
          </div>
          <div class="meeting-app-info-body" v-if="isLoggedIn">
            <div
              class="row meeting-app-info-item"
              v-for="handout in session.Handouts"
              :key="handout.HandoutURL"
            >
              <a
                :href="handout.HandoutURL"
                target="_blank"
                @click="
                  logHandoutClick(handout.HandoutTitle, handout.HandoutURL)
                "
                >{{ handout.HandoutTitle }}</a
              >
            </div>
          </div>
          <div class="meeting-app-info-body" v-else>
            <div class="row meeting-app-info-item">
              <router-link
                :to="{
                  name: 'Login',
                  query: { redirect: $route.fullPath },
                }"
                class="text-link-light-bg"
              >
                Login to View Handouts
              </router-link>
            </div>
          </div>
        </div>
        <div class="meeting-app-info-block" v-else>
          <div class="meeting-app-info-header row">
            <h4>Handouts</h4>
          </div>
          <div class="meeting-app-info-body">
            <div class="row meeting-app-info-item">
              No handouts available for this session.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SessionTimeString from "@/components/SessionTimeString.vue";
import DetailsLoading from "../components/DetailsLoading.vue";
import AttendeeNotes from "@/components/AttendeeNotes.vue";
import CMESessionCard from "@/components/CMESessionCard.vue";
import Analytics from "@/mixins/analytics";

export default {
  data() {
    return {
      sessionId: this.$route.params.id ? this.$route.params.id : this.$route.params.sessionCode,
    };
  },
  metaInfo() {
    return {
      title: this.session ? this.session.Name : "Session Details",
    };
  },
  mounted() {
    this.getSessions();
    this.getSpeakers();
    this.getFavorites();
    this.getSessionSchedules();
    this.getCMEClaims();

    // Scroll to top of the page
    window.scrollTo(0, 0);
  },
  beforeDestroy() {},
  destoryed() {},
  beforeRouteLeave(to, from, next) {
    if (this.isLoggedIn && this.$refs.notes.hasUnsavedChanges) {
      if (confirm("You have unsaved notes, do you want to leave this page?")) {
        // said to leave anyways.
        next();
      } else {
        next(false);
      }
    } else {
      // no unsaved changes
      next();
    }
  },
  methods: {
    getSessions() {
      this.$store.dispatch("loadSessions");
    },
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    getCMEClaims() {
      this.$store.dispatch("loadAttendeeCMEClaims");
    },
    getFavorites() {
      this.$store.dispatch("loadFavorites");
    },
    getSessionSchedules() {
      this.$store.dispatch("loadSessionSchedules");
    },
    toggleFavorite() {
      this.$store.dispatch("toggleFavorite", this.session.id);
    },
    speakersForSchedule(schedule) {
      // The check for speakerRefs == 0 was added because of a bug in the mobile app and needing to feed in garbage data to make it work,
      // it needs to be ignored for now and no speaker name displayed.
      // In the future this should be removed.
      if (schedule.SpeakerRefs == null || schedule.SpeakerRefs == "0") {
        return "";
      } else if (schedule.SpeakerRefs.includes(",")) {
        let output = [];
        let speakerRefs = schedule.SpeakerRefs.split(",");
        for (let i = 0; i < speakerRefs.length; i++) {
          output.push(
            this.getNameFromSpeaker(this.getSpeakerById(speakerRefs[i]))
          );
        }

        return output.join("; ");
      } else {
        return this.getNameFromSpeaker(
          this.getSpeakerById(schedule.SpeakerRefs)
        );
      }
    },
    getNameFromSpeaker(speaker) {
      if (speaker) {
        return speaker.DisplayName;
      } else {
        return "TBD";
      }
    },
    logNaviagationToSpeaker(speakerId, displayName) {
      // Log to analytics
      Analytics.logEvent("aad_speaker_click", {
        search_term: null,
        speaker_id: speakerId,
        speaker_display_name: displayName,
        screen_origin: "session",
      });
    },
    logHandoutClick: function (handoutTitle, handoutUrl) {
      // Log to analytics
      Analytics.logEvent("aad_handout_click", {
        file_name: handoutTitle,
        handout_url: handoutUrl,
      });
    },
  },
  components: {
    SessionTimeString,
    DetailsLoading,
    AttendeeNotes,
    CMESessionCard,
  },
  computed: {
    ...mapGetters([
      "allSessions",
      "getSessionById",
      "getSpeakerById",
      "allSpeakers",
      "isSessionInFavorites",
      "isLoggedIn",
      "sessionSchedulesBySessionId",
    ]),
    session() {
      return this.getSessionById(this.sessionId);
    },
    directors: function () {
      return this.session.Speakers
        ? this.session.Speakers.filter((speaker) => {
            return speaker.Role == "Director";
          })
        : null;
    },
    codirectors: function () {
      return this.session.Speakers
        ? this.session.Speakers.filter((speaker) => {
            return speaker.Role == "Co-Director";
          })
        : null;
    },
    speakers: function () {
      return this.session.Speakers
        ? this.session.Speakers.filter((speaker) => {
            return speaker.Role == "Speaker";
          })
        : null;
    },
    // Retrieve the full records as we will need it for disclosures.
    allSessionFaculty: function () {
      let output = [];
      for (let i = 0; i < this.session.Speakers.length; i++) {
        let match = this.allSpeakers.find((speaker) => {
          return speaker.id == this.session.Speakers[i].SpeakerId;
        });
        if (match) output.push(match);
      }
      return output;
    },
    isFavorited: function () {
      return this.isSessionInFavorites(this.session.id);
    },
    sessionSchedules() {
      return this.sessionSchedulesBySessionId(this.session.id);
    },
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new IDs and clearing it...
      if(this.$route.params.id != undefined) {
        this.sessionId = this.$route.params.id;
      }
      if(this.$route.params.sessionCode != undefined) {

        this.sessionId = this.$route.params.sessionCode;
      }
    },
  },
};
</script>

<style scoped>
.favorite-star {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.star-filled {
  color: #f7d131;
}

.star-filled:hover {
  color: #f7e79f;
}

.star-thick {
  color: #fff;
}

.star-thick:hover {
  color: #f7e79f;
}

.meeting-app-data {
  padding-left: 4px;
}
</style>
